import {
    AfterViewInit,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeWhile } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';

export class BreadcrumbItem {
    text: string;
    routerLink?: string;
    navigationExtras?: NavigationExtras;

    constructor(text: string, routerLink?: string, navigationExtras?: NavigationExtras) {
        this.text = text;
        this.routerLink = routerLink;
        this.navigationExtras = navigationExtras;
    }

    isLink(): boolean {
        return !!this.routerLink;
    }
}

@Component({
    selector: 'sub-header',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.css']
})
export class SubHeaderComponent extends AppComponentBase implements OnInit, OnDestroy, AfterViewInit {
    @Input() forReport: boolean;
    @Input() title: string;
    @Input() description: string;
    @Input() breadcrumbs: BreadcrumbItem[];
    @Input() routerLink?: string;
    @Input() isMultiple: boolean;
    @Input() isAdd: boolean;
    @Input() showCard = false;
    @Input() filter = true;
    @Input() export = false;
    @Input() import = false;
    @Input() isDualTable: boolean;
    @Input() isSimpleHeader: boolean;
    @Output() cardShowEvent = new EventEmitter<boolean>();
    @Output() filterEvent = new EventEmitter<boolean>();
    @Output() dualTableEvent = new EventEmitter<boolean>();
    @Output() searchEvent = new EventEmitter<any>();
    @Output() searchEventOnEnter = new EventEmitter<any>();
    @Output() excelEvent = new EventEmitter<any>();
    @Output() pdfEvent = new EventEmitter<any>();
    // @Output() ExportexcelEvent = new EventEmitter<any>();
    @Output() ImportexcelEvent = new EventEmitter<any>();
    // @ViewChild(DataTableComponent) dataTable;

    shortcuts: ShortcutInput[] = [];
    // ageingReport: boolean;
    filterText: any;
    uploadUrl: string;
    advancedFiltersAreShown: boolean;
    // advancedFiltersAreShown= false;
    simpleTable = false;
    // isTabletScreen: boolean;
    // isMobileScreen: boolean;
    extant = true;
    subscriptions: Subscription[] = [];
    // isDesktop = window.innerWidth > 768;
    dataGrid = true;
    isPDF: boolean;

    constructor(
        injector: Injector,
        private _router: Router,
        private route: ActivatedRoute,
        private breakpointObserver: BreakpointObserver,
        private _activatedRoute: ActivatedRoute) {
        super(injector);
        this.filterText = this._activatedRoute.snapshot.queryParams['filterText'] || '';
        this.uploadUrl = AppConsts.remoteServiceBaseUrl + '/Users/ImportFromExcel';
    }

    routeToAdd() {
        if (this.isAdd === true) {
            this._router.navigate(['add'], { relativeTo: this.route });
        } else {
            this.cardShowEvent.emit(true);
        }
    }

    routeToAddMultiple() {
        if (this.isMultiple === true) {
            this._router.navigate(['addMultiple'], { relativeTo: this.route });
        }
    }

    routeToAEscape() {
        this._router.navigate(['addMultiple'], { relativeTo: this.route });
    }

    openFilter() {
        this.filterEvent.emit(true);
        this.advancedFiltersAreShown = true;
    }

    closeFilter() {
        this.filterEvent.emit(false);
        this.advancedFiltersAreShown = false;
    }

    openSimpleTable() {
        this.dualTableEvent.emit(true);
        this.simpleTable = true;
    }

    openSuktasTable() {
        this.dualTableEvent.emit(false);
        this.simpleTable = false;
    }

    searchFilter(e: string) {
        this.searchEvent.emit(e);
    }

    searchFilerOnEnter(e: any) {
        this.searchEventOnEnter.emit(e);
    }

    goToBreadcrumb(breadcrumb: BreadcrumbItem): void {
        if (!breadcrumb.routerLink) {
            return;
        }

        if (breadcrumb.navigationExtras) {
            this._router.navigate([breadcrumb.routerLink], breadcrumb.navigationExtras);
        } else {
            this._router.navigate([breadcrumb.routerLink]);
        }
    }

    ngAfterViewInit() {

        this.shortcuts.push(
            {
                key: 'alt + n',
                preventDefault: true,
                label: 'Add',
                description: 'Add component',
                command: () => this.routeToAdd()
            },
            {
                key: 'alt + m',
                preventDefault: true,
                label: 'Add Multiple',
                description: 'Add Multiple component',
                command: () => this.routeToAddMultiple()
            },
            {
                key: 'alt + down',
                preventDefault: true,
                label: 'Show Advance Filter',
                description: 'Show advance filter',
                command: () => this.openFilter()
            },
            {
                key: 'alt + up',
                preventDefault: true,
                label: 'Hide Advance Filter',
                description: 'Hide advance filter',
                command: () => this.closeFilter()
            },
            {
                key: 'esc',
                preventDefault: true,
                label: 'Navigate previous router link',
                description: 'Navigate to previous router link',
                command: () => this.closeFilter()
            }
        );
    }

    exportToExcel(e) {
        if (e) {
            this.export = true;

            this.excelEvent.emit(e);
            this.excelEvent.subscribe(e => {
                this.notify.error(e);
            });
        }

    }

    getPdf(e) {
        // this.dataTable.exportToExcel();
        this.pdfEvent.emit(e);
    }

    exportToExcelOperations(e) {

        this.excelEvent.emit(e);
    }

    importToExcelOperations(e) {
        this.ImportexcelEvent.emit(e);
    }

    onUploadExcelError(): void {
        this.notify.error(this.l('ImportUsersUploadFailed'));
    }

    ngOnInit(): void {
        this.subscribeWhenLayoutChanges();
        if (this.forReport) {
            this.advancedFiltersAreShown = true;
            this.forReport = false;
        } else {
            this.advancedFiltersAreShown = false;
        }


    }

    /**
     * @method subscribeWhenLayoutChanges
     * Layout change automatically when subscription takes place
     */

    subscribeWhenLayoutChanges(): void {
        const layoutChanges = this.breakpointObserver.observe(['(max-width: 1199.98px) and (min-width: 768px)', '(max-width: 767.98px)']);
        layoutChanges.pipe(takeWhile(() => this.extant)).subscribe(() => {
            // this.isTabletScreen = this.breakpointObserver.isMatched("(max-width: 1199.98px) and (min-width:768px)");
            // this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767.98px)");
        });
    }

    ngOnDestroy(): void {
        // Observables life is destoyed
        this.extant = false;
        this.unsubscribe();
    }

    /**
     * @method unsubscribe
     * For unsubscribing the observable
     */

    unsubscribe(): void {
        if (this.subscriptions && this.subscriptions.length) {
            this.subscriptions.forEach((s) => {
                if (s) {
                    s.unsubscribe();
                }
            });
        }
    }


    showCards() {
        this.cardShowEvent.emit(false);
        //    this.showCard = true;
    }


}
