import {
  AbpMultiTenancyService,
  FeatureCheckerService,
  LocalizationService,
  MessageService,
  NotifyService,
  PermissionCheckerService,
  SettingService,
} from 'abp-ng2-module';
import { Component, Injector, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import {
  BranchListDto,
  CreateOrEditLoyaltySettingDto,
  DateSelectsServiceProxy,
  ReportingServiceProxy,
  TenantSettingsServiceProxy,
  UiCustomizationSettingsDto,
} from '@shared/service-proxies/service-proxies';
import '@shared/service-proxies/tenant-login-info-dto-extensions';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { FormArray, FormGroup } from '@angular/forms';
import { PaginatorService } from '@app/shared/common/paginator/paginator.service';
import { ISuktasPaginator, SuktasPaginator } from '@app/shared/common/paginator/paginator.component';
import { lastValueFrom, Observable } from 'rxjs';
import { CartService } from '@shared/service-proxies/cart.service';
import { NepaliDatepickerPrivateService } from '@app/shared/common/nepalidatepicker/services/nepali-datepicker-angular-private.service';
import { DateTime } from 'luxon';

interface AbpEventSubscription {
  eventName: string;
  callback: (...args: any[]) => void;
}

@Component({
  template: '',
})
export abstract class AppComponentBase implements OnDestroy {
 // emptyguId = "0";
  emptyguId = '00000000-0000-0000-0000-000000000000';
  isMobile = window.innerWidth <= 768;
  isDesktop = window.innerWidth > 768;
  middleArrInx: number;
  branchDropdown: BranchListDto[] = [];
  qtyValidationType: string;
  godowns: boolean = false;
  units: boolean = false;
  isPrint: boolean = false;
  racks: boolean = false;
  batches: boolean = false;
  taxes: boolean = false;
  productcodes: boolean = false;
  isImeiEnabled: boolean = false;
  getdatemiti: string;
  getbranchId: string;
  getfrommiti: string;
  gettomiti: string;
  showBranch: boolean = false;
  brands: boolean = false;
  discountAmount: boolean = false;
  discountPercent: boolean = false;
  salesRate: boolean = false;
  models: boolean = false;
  sizes: boolean = false;
  batch: boolean = false;
  //enter backspace
  textfilledbull: boolean;
  numberfillednull: boolean;
  ngfillednull: boolean;
  arrayfilledNumNull: boolean;
  arrayfilledTextnull: boolean;
  baseurl = AppConsts.appBaseUrl;
  cartService: CartService;
  // autoLoginTenant: boolean = AppConsts.autoLogin;
  // autoTenantId: number = AppConsts.tenantId;

  // formChangeSubject$ : BehaviorSubject<boolean>;
  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
  localization: LocalizationService;
  permission: PermissionCheckerService;
  feature: FeatureCheckerService;
  notify: NotifyService;
  setting: SettingService;
  message: MessageService;
  multiTenancy: AbpMultiTenancyService;
  appSession: AppSessionService;
  primengTableHelper: PrimengTableHelper;
  ui: AppUiCustomizationService;
  appUrlService: AppUrlService;
  spinnerService: NgxSpinnerService;
  dateService: DateSelectsServiceProxy;
  tenantSettings: TenantSettingsServiceProxy;
  paginatorService: PaginatorService;
  appReporting: ReportingServiceProxy;
  public suktasRpag: ISuktasPaginator = new SuktasPaginator();
  eventSubscriptions: AbpEventSubscription[] = [];
  private ngxSpinnerTextService: NgxSpinnerTextService;
  nepaliDateService: NepaliDatepickerPrivateService;
  today: string;
  isAbt = false;
  fromdate: string;
  todate: string;
  isBarcode = false;
  showSalesAdditional = false;
  loyaltySetting: CreateOrEditLoyaltySettingDto;
  isLoyaltyPoint = false;

  constructor(injector: Injector) {
    this.appReporting = injector.get(ReportingServiceProxy);
    this.localization = injector.get(LocalizationService);
    this.permission = injector.get(PermissionCheckerService);
    this.feature = injector.get(FeatureCheckerService);
    this.notify = injector.get(NotifyService);
    this.setting = injector.get(SettingService);
    this.message = injector.get(MessageService);
    this.multiTenancy = injector.get(AbpMultiTenancyService);
    this.appSession = injector.get(AppSessionService);
    this.ui = injector.get(AppUiCustomizationService);
    this.appUrlService = injector.get(AppUrlService);
    this.primengTableHelper = new PrimengTableHelper();
    this.spinnerService = injector.get(NgxSpinnerService);
    this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
    this.paginatorService = injector.get(PaginatorService);
    this.dateService = injector.get(DateSelectsServiceProxy);
    this.tenantSettings = injector.get(TenantSettingsServiceProxy);
    this.nepaliDateService = injector.get(NepaliDatepickerPrivateService);
  }

  get currentTheme(): UiCustomizationSettingsDto {
    return this.appSession.theme;
  }

  get appLogoSkin(): string {
    if (this.currentTheme.isTopMenuUsed || this.currentTheme.isTabMenuUsed) {
      return this.currentTheme.baseSettings.layout.darkMode ? 'light' : 'dark';
    }

    return this.currentTheme.baseSettings.menu.asideSkin;
  }

  get containerClass(): string {
    if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
      return 'app-container container-fluid';
    } else if (
      this.appSession.theme.baseSettings.layout.layoutType === 'fixed' ||
      this.appSession.theme.baseSettings.layout.layoutType === 'fluid-xxl'
    ) {
      return 'app-container container-xxl';
    }

    return 'app-container container';
  }

  get currentFinancialYear(): any {
    let date = this.dateService.getSelectDate();
    return date;
  }

  get theme2TableMargin(): string {
    if (this.currentTheme.baseSettings.theme === 'theme2') {
      return 'theme2_main_div';
    }
    return '';
  }

  get theme2Width(): string {
    if (this.currentTheme.baseSettings.theme === 'theme2') {
      return 'theme2Width';
    }
    return '';
  }

  get defaultContainer(): string {
    if (this.currentTheme.baseSettings.theme === 'default') {
      return 'default-container';
    }
    return '';
  }

  pdfToggle() {
    this.cartService.toggleIsPdf(true);
  }

  getSetting() {
    if (!this.getbranchId || !this.getdatemiti)
      this.dateService.getSelectDate().subscribe((res) => {
        this.getdatemiti = res.selectedMiti;
        this.getbranchId = res.branchId;
      });

    if (!this.getfrommiti || !this.gettomiti)
      this.appReporting.getFinancialYears().subscribe((res) => {
        this.getfrommiti = res.fromMiti;
        this.fromdate = res.fromDate.toFormat('yyyy/MM/dd');
        this.todate = res.toDate.toFormat('yyyy/MM/dd');
        this.gettomiti = res.toMiti;
      });

    if (this.branchDropdown.length === 0) {
      this.appReporting.getAllBranchSharedDropdown().subscribe((data) => {
        this.branchDropdown = data;
        if (data.length <= 1) {
          this.showBranch = false;
        } else {
          this.showBranch = true;
        }
      });
      this.today = this.nepaliDateService.getCurrentNepaliDate();
    }

    this.currentFinancialYear.subscribe((data) => {
      this.getbranchId = data.branchId;
    });

    this.appReporting.getAllTenantSetting().subscribe((data) => {
      this.qtyValidationType = data.negativeCashTranscation;
      this.godowns = data.allowGodown;
      this.isLoyaltyPoint = data.isLoyaltyPoint;
      this.isBarcode = data.barcode;
      this.productcodes = data.showProdutCode;
      this.units = data.showUnit;
      this.racks = data.allowRack;
      this.taxes = data.isTax;
      this.isPrint = data.tickPrintAfterSave;
      this.brands = data.showBrand;
      this.discountAmount = data.showDiscountAmount;
      this.discountPercent = data.showDiscountPercentage;
      this.salesRate = data.showSalesRate;
      this.models = data.showModelNo;
      this.sizes = data.showSize;
      this.batch = data.allowBatchEnabled;
      this.isImeiEnabled = data.isIMEI;
      this.productcodes = data.showProdutCode;
      this.isImeiEnabled = data.isIMEI;
      this.isAbt = data.isAbt;
      this.showSalesAdditional = data.showSalesAdditional;
    });

    //  this.qtyValidationType = abp.setting.get('App.Suktas.NegativeCashTranscation');
    // this.godowns = abp.setting.getBoolean('App.Suktas.AllowGodOwn');
    // this.productcodes = abp.setting.getBoolean('App.Suktas.ShowProdutCode');
    // this.units = abp.setting.getBoolean('App.Suktas.ShowUnit');
    // this.racks = abp.setting.getBoolean('App.Suktas.AllowRack');
    // this.taxes = abp.setting.getBoolean('App.Suktas.IsTax');
    // this.isPrint = abp.setting.getBoolean('App.Suktas.TickPrintAfterSave');
    // this.brands = abp.setting.getBoolean('App.Suktas.ShowBrand');
    // this.discountAmount = abp.setting.getBoolean('App.Suktas.ShowDiscountAmount');
    // this.discountPercent = abp.setting.getBoolean('App.Suktas.ShowDiscountPercentage');
    // this.salesRate = abp.setting.getBoolean('App.Suktas.ShowSalesRate');
    // this.models = abp.setting.getBoolean('App.Suktas.ShowModelNo');
    // this.sizes = abp.setting.getBoolean('App.Suktas.ShowSize');
    // this.batch = abp.setting.getBoolean('App.Suktas.AllowBatchEnabled');
    // this.isImeiEnabled=abp.setting.getBoolean('App.Suktas.IsIMEI');
    // this.productcodes =abp.setting.getBoolean('App.Suktas.ShowProdutCode');
    // this.isAbt =abp.setting.getBoolean('App.Suktas.IsAbt');

    this.getLoyaltySetting();
  }

  getLoyaltySetting(): void {
    this.appReporting.getLoyaltySetting().subscribe((result) => {
      this.loyaltySetting = result;
    });
  }

  ngOnDestroy(): void {
    this.unSubscribeAllEvents();
  }

  flattenDeep(array) {
    return array.reduce((acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)), []);
  }

  l(key: string, ...args: any[]): string {
    args.unshift(key);
    args.unshift(this.localizationSourceName);
    return this.ls.apply(this, args);
  }

  ls(sourcename: string, key: string, ...args: any[]): string {
    let localizedText = this.localization.localize(key, sourcename);

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, this.flattenDeep(args));
  }

  isGranted(permissionName: string): boolean {
    return this.permission.isGranted(permissionName);
  }

  isGrantedAny(...permissions: string[]): boolean {
    if (!permissions) {
      return false;
    }

    for (const permission of permissions) {
      if (this.isGranted(permission)) {
        return true;
      }
    }

    return false;
  }

  s(key: string): string {
    return abp.setting.get(key);
  }

  appRootUrl(): string {
    return this.appUrlService.appRootUrl;
  }

  showMainSpinner(text?: string): void {
    this.ngxSpinnerTextService.setText(text);
    this.spinnerService.show();
  }

  titleCase(form: FormGroup, str: string, name: string) {
    let separateWord = str.split(' ');
    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    const final = separateWord.join(' ');
    form.get(name).setValue(final);
  }

  // enum {string = number}
  public _prepareSelectOptions = (enums: any) => {
    return Object.keys(enums)
      .filter((f) => !isNaN(Number(f)))
      .map((k: string) => {
        return { id: parseInt(k), displayName: enums[parseInt(k)] };
      });
  };

  /**
   * It takes a form, a string, and a name, and sets the value of the form control with the given name
   * to the given string, converted to uppercase.
   * </code>
   * @param form - The form that the field is in.
   * @param str - The string to be converted to uppercase.
   * @param name - The name of the form control.
   */
  uppercase(form, str, name) {
    form.get(name).setValue(str.toUpperCase());
  }

  //new added chiran

  hideMainSpinner(): void {
    this.spinnerService.hide();
  }

  checkFormArrdisableOnEdit(formArrName: FormArray, formGroupIndx: number) {
    if (formGroupIndx >= 0) {
      for (let i = 0; i < formArrName.length; i++) {
        formArrName.controls.at(i).disable();
      }
      formArrName.controls.at(formArrName.length - 1).enable();
    }
  }

  checkTeaxtfilled(filename, form) {
    const data = form.get(filename).value;

    if (data == '') {
      this.textfilledbull = true;
    } else {
      this.textfilledbull = false;
    }
  }

  checkNumberfilled(filename, form) {
    const data = form.get(filename).value;
    if (data == null) {
      this.numberfillednull = true;
    } else {
      this.numberfillednull = false;
    }
  }

  checkngfilled(e) {
    const data = e.target.value;
    if (data == '') {
      this.ngfillednull = true;
    } else {
      this.ngfillednull = false;
    }
  }

  public exportAsXLSX(data): void {
    // this.excelService.exportAsExcelFile(data, 'sample');
  }

  public twoDec = (s): string => {
    return (Math.round(s * 100) / 100).toFixed(2);
  };

  protected subscribeToEvent(eventName: string, callback: (...args: any[]) => void): void {
    abp.event.on(eventName, callback);
    this.eventSubscriptions.push({
      eventName,
      callback,
    });
  }

  private unSubscribeAllEvents() {
    this.eventSubscriptions.forEach((s) => abp.event.off(s.eventName, s.callback));
    this.eventSubscriptions = [];
  }

  findFormErrors(form: FormGroup): any {
    const errorMessages = {};

    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);

      // Check if it's a FormGroup (nested form)
      if (control instanceof FormGroup) {
        const nestedErrors = this.findFormErrors(control);
        if (Object.keys(nestedErrors).length > 0) {
          errorMessages[key] = nestedErrors;
        }
      } else if (control.errors) {
        errorMessages[key] = control.errors;
      }
    });

    return errorMessages;
  }
}
